import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutStyles from "../styles/pages/about.module.css"

import aboutHeaderImg from "../images/about-header-img.png"
import avp from "../videos/avp.mp4"
import avpPosterImg from "../images/avp-poster-img.png"
import longArrow from "../images/long-arrow.png"

const AboutPage = () => (
    <Layout location="about">
      <SEO title="About" />
      <div className={ aboutStyles.description }>
        <div className={ aboutStyles.descriptionImg }>
          <img src={ aboutHeaderImg }/>
        </div>
        <div className={ aboutStyles.descriptionText }>
          <div className={ aboutStyles.descriptionTextWrapper }>
            <h3>About</h3>
            <p>Need to boost product sales or perk up business via promotional activities?</p>
            <p>Need to form a quick team for a large-scale field work?</p>
            <p>Is it difficult for your company to find and secure qualified staff for office support, field and project-based assignments?</p>
            <p>That's what <strong>T.E.A.M.P.L.A.Y.</strong> is all about. We help you map out a program or a campaign and get you on the road onward to your goals and aspirations.</p>
            <p>Our firm has diverse experience and a proven track record that can help you bring innovative solutions to your company - sell your products, programs, and services through an efficient team of promotional work force and trained staff and act quickly on opportunities.</p>
          </div>
        </div>
      </div>
      <div className={ aboutStyles.videoSection }>
        <div className={ aboutStyles.letsPlay }>
          <img src={ longArrow } alt=""/>
          <h3>Let's Play!</h3>
        </div>
        <div className={ aboutStyles.avpWrapper }>
          <video
              controls
              poster={ avpPosterImg }
              className={ aboutStyles.avp }
          >
              <source src={ avp } type="video/mp4" />
              Your device does not support playing 'video/mp4' videos.
          </video>
        </div>
      </div>
    </Layout>
)

export default AboutPage